import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { environment } from '@project/admin/environments/environment';
import {
  UploadFileGQL,
  UploadFileInput,
  UploadFileMutation,
} from '@project/admin/generated/graphql';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  finalize,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  Subscription,
  tap,
} from 'rxjs';
import { print } from 'graphql';
import { FetchResult } from '@apollo/client/core';
import { fileExtension } from '../shared/utils';

export type UploadResult = ExNull<UploadFileMutation['uploadFile']>['file'];

export const UPLOAD_CONFIG_TOKEN = new InjectionToken<UploadConfig>(
  'Upload config',
);

export interface UploadIdentifiers {
  projectId?: number;
  taskId?: number;
  chatMessageId?: number;
  userId?: number;
}

export interface UploadOptions {
  id?: UploadIdentifiers;
  error?: Error;
  name?: string;
  locked?: boolean;
  preview?: {
    url: string;
    isGeneric: boolean;
  };
}

export interface UploadConfig {
  maxUploadSize: number;
  /**
   * Each extension must start with '.'
   */
  allowedExtensions: string[];

  iconForExtension: (extension: string | null | undefined) => string;
}

export interface Upload extends UploadIdentifiers {
  readonly file: File;
  readonly error?: any;
  readonly name?: string;
  readonly preview?: {
    url: string;
    isGeneric: boolean;
  };
  readonly status: UploadStatus;
  readonly result: UploadResult | null;
  readonly progress: number;
  readonly progress$: Observable<number>;
  readonly status$: Observable<UploadStatus>;
  readonly result$: Observable<UploadResult | null>;
}

export type UploadStatus =
  | 'idle'
  | 'uploading'
  | 'error'
  | 'cancelled'
  | 'uploaded';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private uploadQueue: UploadImpl[] = [];
  private currentUpload: UploadImpl | null = null;
  private _currentUploadSub: Subscription | null = null;

  private get currentUploadSub() {
    return this._currentUploadSub;
  }

  private set currentUploadSub(val) {
    this._currentUploadSub?.unsubscribe();
    this._currentUploadSub = val;
  }

  private uploadUrl = environment.api;

  constructor(
    private gql: UploadFileGQL,
    private http: HttpClient,
    @Inject(UPLOAD_CONFIG_TOKEN) public readonly config: UploadConfig,
  ) {}

  async waitFor(uploads?: Upload[]) {
    if (!uploads || uploads.length === 0) {
      return [];
    }
    await firstValueFrom(forkJoin(uploads.map((u) => u.status$)), {
      defaultValue: 0,
    });
    const failedUploads = uploads
      .filter((u) => u.error)
      .map((u) => u.error.toString());
    if (failedUploads.length > 0) {
      throw new Error(failedUploads.join(', '));
    }
    return uploads.map((u) => u.result).filter((u): u is UploadResult => !!u);
  }

  createFromDropEvent(event: DragEvent, options?: UploadOptions) {
    const dataTransfer = event.dataTransfer;
    const uploads: Upload[] = [];
    if (!dataTransfer) {
      return uploads;
    }
    const items = event.dataTransfer.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind !== 'file') {
          continue;
        }
        const file = items[i].getAsFile();
        if (!file) {
          continue;
        }
        uploads.push(this.createFromFile(file, options));
      }
    } else {
      for (let i = 0; i < dataTransfer.files.length; i++) {
        uploads.push(this.createFromFile(dataTransfer.files[i], options));
      }
    }
    return uploads;
  }

  getDropFiles(event: DragEvent) {
    const dataTransfer = event.dataTransfer;
    const uploads: File[] = [];
    if (!dataTransfer) {
      return uploads;
    }
    const items = event.dataTransfer.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind !== 'file') {
          continue;
        }
        const file = items[i].getAsFile();
        if (!file) {
          continue;
        }
        uploads.push(file);
      }
    } else {
      for (let i = 0; i < dataTransfer.files.length; i++) {
        uploads.push(dataTransfer.files[i]);
      }
    }
    return uploads;
  }

  getInputFiles(element: HTMLInputElement) {
    const files = element.files;
    if (!files) {
      throw new Error('Files not found');
    }
    const uploads = [];
    for (let i = 0; i < files.length; i++) {
      uploads.push(files[i]);
    }
    return uploads;
  }

  createFromFileInput(element: HTMLInputElement, options?: UploadOptions) {
    const files = element.files;
    if (!files) {
      throw new Error('Files not found');
    }
    const uploads = [];
    for (let i = 0; i < files?.length; i++) {
      uploads.push(this.createFromFile(files[i], options));
    }
    return uploads;
  }

  createFromFile(file: File, options?: UploadOptions): Upload {
    const upload = new UploadImpl(file);
    if (file.size > this.config.maxUploadSize) {
      upload.error = new Error('File too large');
      upload.status$.next('error');
    }
    if (
      !this.config.allowedExtensions.includes(fileExtension(file.name) ?? '')
    ) {
      upload.error = new Error('File type not allowed');
      upload.status$.next('error');
    }
    if (options) {
      upload.name = options.name;
      upload.preview = options.preview ?? {
        url: this.genericPreview(file.name),
        isGeneric: true,
      };
      upload.data = {
        ...options.id,
        isLocked: options.locked,
      };
    }
    return upload;
  }

  cancel(upload: Upload | Upload[]) {
    if (Array.isArray(upload)) {
      upload.forEach((u) => this.cancel(u));
      return;
    }
    switch (upload.status) {
      case 'cancelled':
      case 'uploaded':
      case 'error':
        return;
    }
    (upload as unknown as UploadImpl).status$.next('cancelled');
    if (this.currentUpload === upload) {
      this._currentUploadSub?.unsubscribe();
    }
    this.processUploads();
  }

  add(upload: Upload | Upload[]) {
    if (Array.isArray(upload)) {
      this.uploadQueue.push(...(upload as UploadImpl[]));
    } else {
      this.uploadQueue.push(upload as UploadImpl);
    }
    this.processUploads();
  }

  private async processUploads() {
    if (this.currentUploadSub && !this.currentUploadSub.closed) {
      return;
    }
    for (const upload of this.uploadQueue) {
      if (upload.status !== 'idle') {
        continue;
      }
      this.currentUpload = upload;
      this.currentUploadSub = this.upload(
        upload as any as UploadImpl,
      ).subscribe(() => this.processUploads());
      return;
    }
  }

  genericPreview(path: string) {
    return this.iconForExtension(fileExtension(path));
  }

  iconForExtension(ext: string | null | undefined) {
    return this.config.iconForExtension(ext);
  }

  private upload(upload: UploadImpl): Observable<UploadResult> {
    if (upload.status != 'idle') {
      throw new Error('Already executed');
    }
    const data: UploadFileInput = {
      file: upload.file,
      ...upload.data,
    };
    upload.status$.next('uploading');
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: print(this.gql.document),
        variables: { data },
      }),
    );
    formData.append(
      'map',
      JSON.stringify({
        file: ['variables.data.file'],
      }),
    );
    formData.append('file', upload.file, upload.name ?? upload.file.name);

    return this.http
      .post(this.uploadUrl, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            upload.progress$.next((100 * event.loaded) / event.total);
          }
        }),
        filter(
          (event): event is HttpResponse<FetchResult<UploadFileMutation>> =>
            event.type === HttpEventType.Response,
        ),
        map(({ body }) => {
          if (!body) {
            throw new Error('Empty body');
          }
          if (body.errors) {
            throw body.errors[0];
          }
          const result = body.data?.uploadFile?.file ?? null;
          if (!result) {
            throw new Error('Result is empty');
          }
          upload.result$.next(result);
          upload.status$.next('uploaded');
          return result;
        }),
        catchError((err) => {
          upload.error = err;
          upload.status$.next('error');
          return EMPTY;
        }),
        finalize(() => {
          upload.result$.complete();
          upload.status$.complete();
          upload.progress$.complete();
        }),
      );
  }
}

class UploadImpl implements Upload {
  error?: any;
  preview?: { url: string; isGeneric: boolean } | undefined;
  progress$ = new BehaviorSubject<number>(0);
  status$ = new BehaviorSubject<UploadStatus>('idle');
  result$ = new BehaviorSubject<UploadResult | null>(null);
  data?: UploadFileInput;
  name?: string;

  get progress() {
    return this.progress$.value;
  }

  get status() {
    return this.status$.value;
  }

  get result() {
    return this.result$.value;
  }

  constructor(public readonly file: File) {}
}
