import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { EventType, NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class IntlService {
  private readonly _relativeTime: Record<string, Intl.RelativeTimeFormat> = {};
  private readonly _regionNames: Record<string, Intl.DisplayNames> = {};

  private _lang = 'lt';
  get lang() {
    return this._lang;
  }

  regionNames() {
    return (this._regionNames[this._lang] ??= new Intl.DisplayNames(
      this._lang,
      {
        type: 'region',
      },
    ));
  }

  relativeTime() {
    return (this._relativeTime[this.lang] ??= new Intl.RelativeTimeFormat(
      this.lang,
      {
        numeric: 'auto',
      },
    ));
  }

  constructor(
    router: Router,
    private readonly location: PlatformLocation,
  ) {
    this.updateLang();
    router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.updateLang();
      }
    });
  }

  private updateLang() {
    this._lang = new URLSearchParams(this.location.search).get('lang') ?? 'lt';
  }
}
